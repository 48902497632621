<template>
  <v-app>
    <router-view v-if="isAuthorized()" :key="$route.path"/>
    <div v-if = isUnauthorized class="main">
    <div class="w3-display-middle">
    <h1 class="w3-jumbo w3-animate-top w3-center"><code>Not Authorized</code></h1>
    <hr class="w3-border-white w3-animate-left" style="margin:auto;width:50%">
    <h3 class="w3-center w3-animate-right">You are not authorized to access to this site.</h3>
    <h6 class="w3-center w3-animate-zoom">error code:401 unauthorized</h6>
    </div>
    </div>
  </v-app>
</template>

<script>
import axios from 'axios'
export default {
    name: 'App',
    data() {
        return {
            showDialog: false,
            msg: '',
            isUnauthorized: false
        }
    },
    mounted() {
          let view = this
          if(this.$utils.isValidToken() === false && this.isPortalAccessEmpty()) {
              view.$utils.setCookies('lastVisitedUrl', location.origin + this.$router.history.current.path, view)
              this.checkSessionExist()
          }else{
            this.isAuthorized()
          }
    },
    beforeCreate() {
    },
    created() {
      var view = this
        window.addEventListener('touchstart', () => {
            const currentDate = new Date();
            const startTime = currentDate.getTime();
            view.$utils.setCookies('startTime', startTime, view)
        });
        window.addEventListener('mousemove',() => {
            const currentDate = new Date();
            const startTime = currentDate.getTime();
            view.$utils.setCookies('startTime', startTime, view)

        });
        window.addEventListener('mousedown',() => {
            const currentDate = new Date();
            const startTime = currentDate.getTime();
            view.$utils.setCookies('startTime', startTime, view)

        });
        window.addEventListener('keydown',() => {
            const currentDate = new Date();
            const startTime = currentDate.getTime();
            view.$utils.setCookies('startTime', startTime, view)
        });
        this.interval = setInterval(() => this.idleredirect(), 60000);  //watcher for auto logout
        this.interval = setInterval(() => this.refreshToken(), 60000);
        this.interval = setInterval(() => this.signoutRedirectForSubdomainApps(), 1000); //watcher for manual logout
    },
    methods: {
      idleredirect: function() {
            const currentDate = new Date();
            const currentTime = currentDate.getTime();
            const diff = currentTime - parseInt(this.$utils.getCookies('startTime'))
            if(diff >= 1500000){
                let auto_signout_url = this.$url('AUTO_SIGNOUT_URL')
                this.$fetch({ requiresAuth: true }).get(auto_signout_url).then(res => {
                  if(res.data.statusCode === 200){
                        this.$utils.removeCookies('access_token', this)
                        this.$utils.removeCookies('portal_access', this)
                        this.$utils.removeCookies('email',this)
                        this.$utils.removeCookies('username',this)
                        this.$utils.removeCookies('user_id',this)
                        this.$utils.removeCookies('un_pass',this)
                        this.$utils.removeCookies('refresh_token',this)
                        this.$utils.removeCookies('portal_access',this)
                        this.$utils.removeCookies('token_expire_time',this)
                        this.$utils.removeCookies('startTime',this)
                        this.$utils.removeCookies('precedence_url',this)
                        this.$utils.setCookies('isActive', false, this)
                        this.$utils.setCookies('user_role', false, this)
                        this.$utils.setCookies('lastVisitedUrl', location.origin + this.$router.history.current.path, this)
                        //this will redirect to login page
                        location.href = this.$url('SIGNIN_URL')
                    }
                }).catch((error) => {
                    console.error(error);
                });
            }
      },
      //This will redirect to signin page if you logged out from any of the page or subdomain application
      signoutRedirectForSubdomainApps: function() {
        if(this.$utils.getCookies('isSignedIn') ==='false' && !this.isUnauthorized){
            location.href = this.$url('SIGNIN_URL')
        }
      },
      refreshToken: function() {
          var currentDate = new Date();
          let currentTimeInMiliseconds = currentDate.getTime();
          let expireTimeInmiliseconds = this.$utils.getCookies('token_expire_time')
          console.log("expireTimeInmiliseconds:=> " + expireTimeInmiliseconds)
          if(expireTimeInmiliseconds !=null && expireTimeInmiliseconds!='undefined'){
            // Access token expire time set in cookies by reducing 5 minutes
            if(currentTimeInMiliseconds > expireTimeInmiliseconds){
                let refresh_token_url = this.$url('REFRESH_TOKEN_URL')
                axios.get(refresh_token_url, {
                    headers: {
                      Authorization: this.$utils.getCookies('refresh_token'),
                      username: this.$utils.getCookies('username'),
                      userid: this.$utils.getCookies('user_id')
                    }
                }).then(res => {
                  console.log("refresh token called")
                  if(res.data.statusCode == 200){
                          this.$utils.setCookies('access_token', res.data.payload.access_token, this)
                          //set expire time of access token
                          var currentdate = new Date();
                          let minutes = (res.data.payload.access_token_expire_time/60)-5
                          let expire_time = currentdate.setTime(currentdate.getTime() + (minutes * 60 * 1000));
                          this.$utils.setCookies('token_expire_time', expire_time, this)
                  }else{
                          this.$utils.setCookies('isActive', false, this)
                          this.$utils.removeCookies('access_token', this)
                          this.$utils.setCookies('lastVisitedUrl', location.origin + this.$router.history.current.path, this)
                          console.log("in refresh token else")
                          if(confirm("Your session has expired, please login again")){
                              location.href = this.$url('SIGNIN_URL')
                          }else{
                              location.href = this.$url('SIGNIN_URL')
                          }
                        }
                }).catch((error) => {
                      console.error(error);
                      this.$utils.setCookies('isActive', false, this)
                      this.$utils.removeCookies('access_token', this)
                      this.$utils.setCookies('lastVisitedUrl', location.origin + this.$router.history.current.path, this)
                      console.log("in refresh token error")
                      if(confirm("Your session has expired, please login again")){
                          location.href = this.$url('SIGNIN_URL')
                      }else{
                          location.href = this.$url('SIGNIN_URL')
                      }
                });
            }
          }
      },

      isPortalAccessEmpty: function() { //when user login and redirects to portal
        let isEmpty = false
        let urls = this.$utils.getCookies('portal_access')
        if(urls === 'undefined' || urls === null || urls.lenghth === 0){
            isEmpty = true
        }
        return isEmpty
      },
      isAuthorized: function() {
        let authorized = false
        let accessList = []
        let urls=this.$utils.getCookies('portal_access')
        if(urls != 'undefined' && urls != null && urls.indexOf(',') > -1){
            accessList = urls.split(",")
        }else{
            accessList = urls
        }
        var currentUrl = location.origin +'/'
        if(accessList.includes(currentUrl)){
            if(this.$utils.isValidToken()){
                authorized = true
            }else{
                this.checkSessionExist()
            }
        }else{
            this.isUnauthorized = true
        }
        return authorized
      },
      checkSessionExist: function(){
            let view = this
            if(view.$utils.getCookies('isActive') ==='false'){
                alert("Your session has expired, please login again")
                location.href = this.$url('SIGNIN_URL')
            }else if(view.$utils.getCookies('isSignedIn') ==='false'){
                alert("You have been logged out, please login again")
                location.href = this.$url('SIGNIN_URL')
            }else{
                location.href = this.$url('SIGNIN_URL')
            }
      }
  }
}
</script>
<style>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
#select-all-checkbox {
  display: inline-block;
  position: absolute;
  top: -7px;
  left: 16px;
  color: #fff;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 14px;
  font-weight: bold;
  color: #fff!important;
}
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  font-size: 14px !important;
  font-weight: bold !important;
}
.theme--light.v-label {
  color: rgba(0, 0, 0, 0.6) !important;
}
.v-data-table-header {
  background-color: #3f51b5 !important;
  border-color: #3f51b5 !important;
  color: #fff !important;
}
.v-data-table-header {
  color: #fff !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    font-size: 14px!important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    font-size: 13px!important;
    font-weight:400
}
#select-all-checkbox .theme--light.v-label {
  color: #fff !important;
}
.tableWrapper {
  position: relative;
  color:#000;
}
.v-list-item
  .primary--text
  .v-list-item--active
  .v-list-item--link
  .theme--light
  .v-list-item__title {
  color: #fff;
}
.close_button {
  background-color: #cdd2d0;
  color:black;
}
.confirm_button{
  background-color:#3f51b5 !important;
  color: white !important;
}
.v-card-padding{
    padding-left: 20px !important;
    padding-right: 20px !important;
}
h1 {
    color: red;
}

h6{
    color: red;
    text-decoration: underline;
}

.main{
    position: absolute;
    width: 300px;
    height: 200px;
    z-index: 15;
    top: 50%;
    left: 50%;
    margin: -100px 0 0 -150px;
    /* background: red; */
}
.theme--dark.v-navigation-drawer {
    background-color: #ffffff!important;
}

</style>
