export const ISEDIT = 'ISEDIT'
export const TOTAL_SET = 'TOTAL_SET'
export const REQUEST_PAYLOAD = 'REQUEST_PAYLOAD'
export const HASNEXT = 'HASNEXT'
export const RESPONSE_PAYLOAD = 'RESPONSE_PAYLOAD'
export const LASTPAGE = 'LASTPAGE'
export const PERPAGE = 'PERPAGE'
export const SETPAGE = 'SETPAGE'
export const STARTTIME = 'STARTTIME'
export const ENDTIME = 'ENDTIME'
export const ISFROMSEARCH = 'ISFROMSEARCH'
export const ISLOADING = 'ISLOADING'
export const PAGING = 'PAGING'
export const DUPLICATE_FOR_ID = 'DUPLICATE_FOR_ID'
export const ORIGINAL_HTML = 'ORIGINAL_HTML'
export const SEARCH_QUERY = 'SEARCH_QUERY'
export const SEARCH_BY_ID = 'SEARCH_BY_ID'
export const SEARCH_BY_SUBJECT = 'SEARCH_BY_SUBJECT'